import React from 'react'
import styles from './style.module.scss'
import { Request } from './components'
import { ReactComponent as StickIcon } from '@assets/icons/Stick.svg'
import { ReactComponent as StarsIcon } from '@assets/icons/Stars.svg'
import { useTranslation } from 'react-i18next'

export const PastRequests_shadowgpt: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.PastRequests}>
            <div className={styles.PastRequests__background} />
            <div className={`${styles.PastRequests__content} app-container`}>
                <div className={styles.PastRequests__textContainer}>
                    <h2>{t('humanizer.review.title')}</h2>
                    <p>{t('humanizer.review.text_shadowgpt')}</p>
                </div>
                <div className={styles.PastRequests__requestContainer}>
                    <Request
                        name={t('humanizer.review.humanizer')}
                        date={t('humanizer.review.date')}
                        infoCaption={t('humanizer.review.words')}
                        bottomCaption={t('humanizer.review.reHumanizer')}
                        bottomIcon={<StickIcon />}
                    />
                    <Request
                        indicatorColor="red"
                        name={t('humanizer.review.chat')}
                        date={t('humanizer.review.date')}
                        infoCaption={t('humanizer.review.requests')}
                        bottomCaption={t('humanizer.review.reGenerate')}
                        bottomIcon={<StarsIcon />}
                    />
                </div>
            </div>
        </div>
    )
}
