import React, { useState }  from 'react'
import styles from './style.module.scss'
import { ReactComponent as CrownIcon } from '@assets/icons/Crown.svg'
import { $user } from '../../features/app/model'
import { useUnit } from 'effector-react'
import { cancelSubscriptionQuery } from '../../features/app/queries'
import { useTranslation } from 'react-i18next'
import { ConfirmationPopoverUnsubscribe } from '../../components/ConfirmationPopover'
import { handleCancelSubscription } from '../../pages/GenerationBlock/pages/Billings'

export const TarifPlan: React.FC = () => {
    const { t } = useTranslation()
    const user = useUnit($user)
    const [isUnsubscribeConfirmationOpen, setIsUnsubscribeConfirmationOpen] = useState(false);
    const humanizeDate = (date: any) => {
        const month = new Date(date).getUTCMonth() + 1
        const day = new Date(date).getUTCDate()
        const year = new Date(date).getUTCFullYear()

        if (month < 10) {
            return `0${month}` + ' / ' + day + ' / ' + year
        }
        return month + ' / ' + day + ' / ' + year
    }


    return (
        <div className={styles.TarifPlan}>
            {Object.keys(user).length && (
                <div>
                    <h5>Plan details</h5>
                    <div className={styles.TarifPlan__flexMainWrapper}>
                        <div className={styles.TarifPlan__flexWrapper}>
                            <p className={styles.TarifPlan__pContainer}>
                                Your plan
                            </p>
                            <p>-</p>
                            <p className={styles.TarifPlan__grayCaption}>
                                {user.subscription.title}
                            </p>
                        </div>
                        <div className={styles.TarifPlan__flexWrapper}>
                            <p className={styles.TarifPlan__pContainer}>
                                Requests
                            </p>
                            <p>-</p>
                            <p className={styles.TarifPlan__grayCaption}>
                                {Math.abs(
                                    user.subscription.requestsLeft -
                                        user.subscription.totalRequests
                                )}{' '}
                                / {user.subscription.totalRequests}
                            </p>
                        </div>
                        <div className={styles.TarifPlan__flexWrapper}>
                            <p className={styles.TarifPlan__pContainer}>
                                Subscription started
                            </p>
                            <p>-</p>
                            <p className={styles.TarifPlan__grayCaption}>
                                {humanizeDate(user.subscription.createdAt)}
                            </p>
                        </div>
                    </div>
                    <div className={styles.TarifPlan__buttonContainer}>
                        <a
                            href="/pricing"
                            className={styles.TarifPlan__changePlanBtn}
                        >
                            <small>Change plan</small>
                            <CrownIcon width="15" height={11} />
                        </a>
                        <button
                            onClick={() => setIsUnsubscribeConfirmationOpen(true)}
                            className={styles.TarifPlan__cancelSubscriptionBtn}
                        >
                            <small>{t('billings.cancel')}</small>
                        </button>
                    </div>
                    {isUnsubscribeConfirmationOpen && (
                        <ConfirmationPopoverUnsubscribe
                            close={(value) => {
                                setIsUnsubscribeConfirmationOpen(false);
                                if (value) {
                                    handleCancelSubscription();
                                }
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    )
}