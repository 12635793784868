import React from 'react'
import styles from './style.module.scss'
import { ButtonPrimary } from '@components'
import { ReactComponent as StickIcon } from '@assets/icons/Stick.svg'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const StayInShadowBlock_shadowgpt: React.FC = () => {
  const navigate = useNavigate()

  const {t} = useTranslation()

    return (
        <div className={styles.StayInShadowBlock}>
            <div className={styles.StayInShadowBlock__background} />
            <div
                className={`app-container ${styles.StayInShadowBlock__wrapper}`}
            >
                <div className={styles.StayInShadowBlock__flexContainer}>
                    <div className={styles.StayInShadowBlock__textContainer}>
                        <h2> {t("stayIn.title")}</h2>
                        <p>
                            {t("stayIn.desc")}
                        </p>
                    </div>
                    <ButtonPrimary
                        className={styles.StayInShadowBlock__button}
                        icon={<StickIcon />}
                        colorScheme="orange"
                        onClick={() => navigate("/generation/shadow-chat")}
                    >
                       {t("stayIn.btn")}
                    </ButtonPrimary>
                </div>
            </div>
        </div>
    )
}
