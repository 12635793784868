import React from 'react';
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';
import useOnclickOutside from 'react-cool-onclickoutside';
import styles from './style.module.scss';
import SEFlagIcon from '@assets/icons/countryFlags/se.svg';
import USAFlagIcon from '@assets/icons/countryFlags/USAFlag.svg';
import { pricingFx } from '../../features/pricing/model';
import { $userLanguage, $user, updateUserLangFx, userLanaguageEvent } from '../../features/app/model';

interface LanguageInfoProps {
  position: React.CSSProperties;
  set: (value: boolean) => void;
  val: boolean;
}
export const LanguageInfo: React.FC<LanguageInfoProps> = ({ position, set, val }) => {
    const currentLang = useUnit($userLanguage);
    const [user, update] = useUnit([$user, updateUserLangFx]);
    const requestSubs = useUnit(pricingFx);
    const { i18n } = useTranslation();

    const ref = useOnclickOutside(() => {
        set(false);
    });

    // Function to determine the initial language
    const getInitialLanguage = (): 'EN' | 'SV' => {
        const storedLang = localStorage.getItem('i18nextLng');
        if (storedLang === 'sv') return 'SV';
        return 'EN';  // Default to English
    };

    // Set initial language on component mount
    React.useEffect(() => {
        const initialLang = getInitialLanguage();
        changeLanguage(initialLang);
    }, []);

   const changeLanguage = (lang: 'EN' | 'SV') => {
        userLanaguageEvent(lang);
        i18n.changeLanguage(lang.toLowerCase());
        const currencyCode = lang === 'EN' ? 'USD' : 'SEK';
        if (Object.keys(user).length) {
            update({
                languageCode: lang,
                currencyCode: currencyCode,
            }).then(() => {
                requestSubs({
                    currencyCode: currencyCode,
                    languageCode: lang,
                });
            });
        } else {
            requestSubs({
                currencyCode: currencyCode,
                languageCode: lang,
            });
        }
        localStorage.setItem('i18nextLng', lang.toLowerCase());
        set(false);
    };

    const currentLanguage = getInitialLanguage();

    return (
        <div className={styles.LanguageInfo} ref={ref}>
            <div
                className={styles.LanguageInfo__header}
                onClick={() => set(!val)}
            >
                <img src={currentLanguage === 'EN' ? USAFlagIcon : SEFlagIcon} alt="" />
                <p className={styles.LanguageInfo__language}>{currentLanguage}</p>
            </div>
            <div
                style={position}
                className={
                    val
                        ? `${styles.LanguageInfoBody__show} ${styles.LanguageInfoBody}`
                        : styles.LanguageInfoBody
                }
            >
                <div
                    className={styles.LanguageInfoBody__row}
                    onClick={() => changeLanguage('EN')}
                >
                    <img src={USAFlagIcon} alt="" />
                    <p className={styles.LanguageInfo__language}>EN</p>
                </div>
                <div
                    className={styles.LanguageInfoBody__row}
                    onClick={() => changeLanguage('SV')}
                >
                    <img src={SEFlagIcon} alt="" />
                    <p className={styles.LanguageInfo__language}>SE</p>
                </div>
            </div>
        </div>
    );
};