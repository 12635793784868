import React from 'react'
import styles from './style.module.scss'
import { ReactComponent as OutIcon } from './assets/Out.svg'
import { ReactComponent as TrashIcon } from './assets/Trash.svg'
import { ReactComponent as SaveIcon } from './assets/Save.svg'
import { ReactComponent as MailIcon } from './assets/Mail.svg'
import { ReactComponent as UploadIcon } from '@assets/icons/Upload.svg'
import { Input, ButtonPrimary, ButtonTiny, TarifPlan } from '@components'
import { ReactComponent as UserIcon } from '../../../../components/Header/assets/EmptyUser.svg'
import mockAvatar from './assets/MockAvatar.png'
import {
    $updateUser,
    $user,
    updateUserFx,
    userDeleteFx,
    userFx,
} from '../../../../features/app/model'
import { useUnit } from 'effector-react'
import { useNavigate } from 'react-router-dom'
import { constants } from 'crypto'
import { useTranslation } from 'react-i18next'
import { makeToast } from '../../../../shared/ui/toast'

const AccountInfoBlock: React.FC<{
    title: string
    content: any
}> = ({ title, content }) => {
    return (
        <div className={styles.AccountInfoBlock}>
            <h5>{title}</h5>
            {content}
        </div>
    )
}

type PayloadUpdate = {
    firstName: string
    lastName: string
    file?: File
}

export const AccountDetails: React.FC = () => {
    const [user, userLoading] = useUnit([$user, userFx.pending])

    const navigate = useNavigate()

    const [firstName, setFirstName] = React.useState<string>(
        user.firstName || ''
    )
    const [lastName, setLastName] = React.useState<string>(user.lastName || '')
    const [file, setFile] = React.useState<File | null>()
    const [fileDataURL, setFileDataURL] = React.useState<string>()
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const fileInputRef = React.useRef<HTMLInputElement | null>(null)

    const [updatedUser, updateUser] = useUnit([$updateUser, updateUserFx])
    const [requestUser, deleteUser] = useUnit([userFx, userDeleteFx])

    const updateHandler = () => {
        if (firstName.length > 2 && lastName.length > 2) {
            setIsLoading(true)

            const payload: PayloadUpdate = {
                firstName,
                lastName,
                ...(file !== null && { file }),
            }
            updateUser(payload).then((res) => {
                if (res?.data) {
                    setFile(null)
                    setFileDataURL(res?.data.image)
                    user.image = res?.data.image
                    setIsLoading(false)
                    makeToast(
                        'success',
                        'The changes have been successfully updated'
                    )
                }
            })
            requestUser()
        } else
            makeToast('error', 'It is necessary to fill in the required fields')
    }

    React.useEffect(() => {
        setFirstName(user.firstName ? user.firstName : '')
        setLastName(user.lastName ? user.lastName : '')
        setFileDataURL(user.image)
    }, [user])

    const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setFile(event.target.files[0])
        }
    }
    const buttonUploadHandler = () => {
        if (fileInputRef.current) {
            fileInputRef.current?.click()
        }
    }

    React.useEffect(() => {
        let fileReader: FileReader,
            isCancel = false
        if (file) {
            fileReader = new FileReader()
            fileReader.onload = (e: any) => {
                const result = e.target.result
                if (result && !isCancel) {
                    setFileDataURL(result)
                }
            }
            fileReader.readAsDataURL(file)
        }
        return () => {
            isCancel = true
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort()
            }
        }
    }, [file, fileDataURL])

    const deleteAvatar = () => {
        setFile(null)
        setFileDataURL('')
    }

    const deleteUserHandler = () => {
        deleteUser().then((res) => {
            localStorage.removeItem('token')
            localStorage.removeItem('refresh')
            navigate('/')
            window.location.reload()
        })
    }

    const { t } = useTranslation()

    return (
        <div className={styles.AccountDetails}>
            <div className={styles.AccountDetails__background} />
            <div className={styles.AccountDetails__wrapper}>
                <div className={`${styles.AccountDetails__flexWrapper}`}>
                    <AccountInfoBlock
                        title={t('profile.title')}
                        content={
                            <>
                                <div
                                    className={
                                        styles.AccountDetails__avatarContainer
                                    }
                                >
                                    {fileDataURL && !file ? (
                                        <img
                                            src={`data:image/jpeg;base64,${fileDataURL}`}
                                            alt=""
                                        />
                                    ) : null}
                                    {fileDataURL && file ? (
                                        <img src={`${fileDataURL}`} alt="" />
                                    ) : null}
                                    {!user.image && !fileDataURL ? (
                                        <UserIcon
                                            fill="white"
                                            width={80}
                                            height={80}
                                        />
                                    ) : null}
                                    <div
                                        className={
                                            styles.AccountDetails__subAvatarFlexContainer
                                        }
                                    >
                                        <ButtonTiny
                                            onClick={buttonUploadHandler}
                                        >
                                            <UploadIcon />
                                            <p>{t('profile.upload')}</p>
                                        </ButtonTiny>
                                        <input
                                            style={{ display: 'none' }}
                                            type="file"
                                            ref={fileInputRef}
                                            onChange={inputChangeHandler}
                                        />
                                        <small
                                            style={{ cursor: 'pointer' }}
                                            onClick={deleteAvatar}
                                        >
                                            {t('profile.delete')}
                                        </small>
                                    </div>
                                </div>
                                <div className={styles.AccountDetails__input}>
                                    <Input
                                        label="First Name"
                                        placeholder="Thomas"
                                        value={firstName}
                                        onChange={(evt: any) =>
                                            setFirstName(evt.target.value)
                                        }
                                    />
                                </div>
                                <div className={styles.AccountDetails__input}>
                                    <Input
                                        label="Last Name"
                                        placeholder="Miller"
                                        value={lastName}
                                        onChange={(evt: any) =>
                                            setLastName(evt.target.value)
                                        }
                                    />
                                </div>
                                <div className={styles.AccountDetails__input}>
                                    <Input
                                        label={t('profile.email')}
                                        value={
                                            user.email === null
                                                ? ''
                                                : user.email
                                        }
                                        disabled={true}
                                    />
                                </div>
                                <div className={styles.AccountDetails__input}>
                                    <Input
                                        label={t('profile.phone')}
                                        // placeholder="+375333333333"
                                        disabled={true}
                                        value={user.phone}
                                    />
                                </div>
                                <ButtonPrimary
                                    className={
                                        styles.AccountDetails__saveButton
                                    }
                                    colorScheme="orange"
                                    onClick={updateHandler}
                                >
                                    <p>{t('profile.save')}</p>
                                    <SaveIcon />
                                </ButtonPrimary>
                            </>
                        }
                    />
                    <TarifPlan />
                    <AccountInfoBlock
                        title={t('profile.getSupport')}
                        content={
                            <>
                                <p
                                    className={
                                        styles.AccountDetails__getSuppDescription
                                    }
                                >
                                    {t('profile.info')}
                                </p>
                                <a
                                    href="mailto:support@humanizeai.now"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <ButtonTiny>
                                        <p>{t('profile.send')}</p>
                                        <MailIcon />
                                    </ButtonTiny>
                                </a>
                            </>
                        }
                    />
                    <div className={styles.AccountDetails__bottomButtons}>
                        <div
                            className={styles.AccountDetails__bottomButtons_btn}
                            onClick={() => {
                                localStorage.removeItem('token')
                                localStorage.removeItem('refresh')
                                window.location.reload()
                                navigate('/')
                            }}
                        >
                            <p>{t('header.dropdown.logOut')}</p>
                            <OutIcon />
                        </div>
                        <div
                            onClick={deleteUserHandler}
                            className={
                                styles.AccountDetails__bottomButtons_deleteBtn
                            }
                        >
                            <small>{t('profile.deleteAcc')}</small>
                            <TrashIcon />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
