import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { ReactComponent as MastercardIcon } from './assets/Mastercard.svg'
import { ReactComponent as CrownIcon } from '../../components/SideMenu/assets/Crown.svg'
import { InfoBadge } from './components'
import backgroundElement from '../ShadowGPT/assets/backgroundElement.webp'
import { useUnit } from 'effector-react'
import { $billings, billingsFx } from '../../../../features/billings/model'
import { $user, $userLanguage } from '../../../../features/app/model'
import { useNavigate } from 'react-router-dom'
import { $accountSub, accountSubFx } from '../../../../features/pricing/model'
import Pagination from './pagination'
import { useTranslation } from 'react-i18next'
import { cancelSubscriptionQuery } from '../../../../features/app/queries'
import { ConfirmationPopoverUnsubscribe } from '../../../../components/ConfirmationPopover'
import { makeToast } from '../../../../shared/ui/toast'

function formatDateToCustomFormat(dateString: any) {
    var date = new Date(dateString)

    var day: number | string = date.getDate()
    var month: any = date.getMonth() + 1 // Прибавляем 1, так как месяцы в JavaScript начинаются с 0
    var year = date.getFullYear()

    day = day < 10 ? '0' + day : day
    month = month < 10 ? '0' + month : month

    var formattedDate = day + ' / ' + month + ' / ' + year

    return formattedDate
}

export const handleCancelSubscription = async () => {
    try {
        await cancelSubscriptionQuery();
        makeToast('success','Future payments canceled. Your subscription will remain active until next payment date.');
    } catch (error) {
        console.error('Failed to cancel subscription:', error);
        makeToast('error','Failed to cancel subscription. Please contact support');
    }
};

export const Billings: React.FC = () => {
    const navigate = useNavigate()
    const user = useUnit($user)
    const [billings, getBillings, loading] = useUnit([
        $billings,
        billingsFx,
        billingsFx.pending,
    ])
    const currLang = useUnit($userLanguage)
    const [isUnsubscribeConfirmationOpen, setIsUnsubscribeConfirmationOpen] = useState(false);
    
    const [currentTab, setCurrentTab] = useState(1)

    const [accountSub, account, accountLoading] = useUnit([
        accountSubFx,
        $accountSub,
        accountSubFx.pending,
    ])

    useEffect(() => {
        getBillings(currentTab)
        accountSub()
    }, [currentTab])

    const humanizeDate = (date: any) => {
        const month = new Date(date).getUTCMonth() + 1
        const day = new Date(date).getUTCDate()
        const year = new Date(date).getUTCFullYear()

        if (month < 10) {
            return `0${month}` + ' / ' + day + ' / ' + year
        }
        return month + ' / ' + day + ' / ' + year
    }

    const { t } = useTranslation()

    const translateStatus = (text: string, lang: 'EN' | 'SV') => {
        if (lang === 'SV') {
            if (text === 'PROCESSING') {
                return 'Pågående'
            } else if (text === 'COMPLETED') {
                return 'Framgång'
            } else {
                return 'Misslyckas'
            }
        }
        return text.toLowerCase()
    }

    return (
        <div className={styles.Billings}>
            <div className={styles.Billings__noiseBackground} />
            <h2 className={styles.Billings__title}>
                HumanizeAI<span>Billings</span>
            </h2>
            {Object.keys(user).length && Object.keys(accountSub).length && (
                <div className={styles.Billings__wrapper}>
                    <div className={styles.Billings__flexWrapper}>
                        <InfoBadge
                            title={t('billings.plan')}
                            gridText1={t('billings.your')}
                            gridText2={user.subscription.title}
                            gridText3={t('billings.started')}
                            gridContent4={formatDateToCustomFormat(
                                new Date(user.subscription.createdAxt)
                            )}
                            button={
                                <div className={styles.Billings__buttonContainer}>
                                    <div
                                        className={styles.Billings__changePlanBtn}
                                        onClick={() => navigate('/pricing')}
                                    >
                                        <small>{t('billings.change')}</small>
                                        <CrownIcon width="15" hanging={11} />
                                    </div>
                                    <div
                                        className={styles.Billings__cancelSubscriptionBtn}
                                        onClick={() => setIsUnsubscribeConfirmationOpen(true)}
                                    >
                                        <small>{t('billings.cancel')}</small>
                                    </div>
                                </div>
                            }
                        />
                        <InfoBadge
                            title={t('billings.billings')}
                            gridText1={t('billings.method')}
                            gridText2={
                                account.funding
                                    ? `${account.funding.toLowerCase()} Card`
                                    : ''
                            }
                            gridText3={t('billings.number')}
                            gridContent4={
                                <div className={styles.Billings__masterCard}>
                                    <span>
                                        <MastercardIcon />
                                    </span>
                                    <span>
                                        **** **** ****{' '}
                                        {account.last4
                                            ? account.last4
                                            : ' ****'}
                                    </span>
                                </div>
                            }
                            /*
                            button={
                                <div
                                    className={`${styles.Billings__changePlanBtn} ${styles.Billings__changePaymentBtn}`}
                                    onClick={() => navigate('/pricing')}
                                >
                                    <small>{t('billings.change2')}</small>
                                </div>
                            }
                                */
                        />
                    </div>
                    <div className={styles.Billings__transactionTable}>
                        <div
                            className={`${styles.Billings__tableRow} ${styles.Billings__tableRow_first}`}
                        >
                            <p className={styles.Billings__tableItem}>
                                {t('billings.date')}
                            </p>
                            <p className={styles.Billings__tableItem}>
                                {t('billings.amount')}
                            </p>
                            <p
                                className={styles.Billings__tableItem}
                                style={{ paddingRight: '0' }}
                            >
                                {t('billings.status')}
                            </p>
                        </div>
                        {Object.keys(billings).length
                            ? billings?.data.map(
                                  ({ updatedAt, amount, status, currency, i }: any) => (
                                      <div
                                          className={styles.Billings__tableRow}
                                      >
                                          <p
                                              className={
                                                  styles.Billings__tableItem
                                              }
                                          >
                                              {humanizeDate(updatedAt)}
                                          </p>
                                          <p
                                              className={
                                                  styles.Billings__tableItem
                                              }
                                          >
                                              {currency.symbol}{amount}
                                          </p>
                                          <div
                                              className={
                                                  styles.Billings__indicatorContainer
                                              }
                                          >
                                              <div
                                                  className={`${
                                                      styles.Billings__indicator
                                                  } ${
                                                      styles[
                                                          `Billings__indicator_${status}`
                                                      ]
                                                  }`}
                                              />
                                              <p className={''}>
                                                  {translateStatus(
                                                      status,
                                                      currLang
                                                  )}
                                              </p>
                                          </div>
                                      </div>
                                  )
                              )
                            : null}
                    </div>{' '}
                    {billings.meta && (
                        <Pagination
                            perPage={billings.meta.totalItems}
                            length={billings.meta.itemsPerPage}
                            active={billings.meta.currentPage}
                            setCurrentTab={setCurrentTab}
                        />
                    )}
                    {isUnsubscribeConfirmationOpen && (
                        <ConfirmationPopoverUnsubscribe
                            close={(value) => {
                                setIsUnsubscribeConfirmationOpen(false);
                                if (value) {
                                    handleCancelSubscription();
                                }
                            }}
                        />
                    )}
                </div>
            )}
        
        </div>
    )
}
