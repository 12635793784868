import React from "react";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import background404 from "./assets/404Background.webp";
import { ButtonPrimary, Footer } from "@components";

export const ErrorPage: React.FC = () => {
  const navigate = useNavigate();

  const buttonClickHandler = () => {
    navigate("/");
  };

  return (
    <>
      <div className={styles.ErrorPage}>
        <div
          className={styles.ErrorPage__background}
        />
        <h1>404</h1>
        <div>
          <p className={styles.ErrorPage__title}>
            This page isn't available. Sorry about that.
          </p>
          <p className={styles.ErrorPage__title}>
            Try searching for something else.
          </p>
        </div>
        <ButtonPrimary
          onClick={buttonClickHandler}
          className={styles.ErrorPage__button}
          textAlign="center"
          colorScheme="orange"
        >
          Go to Home
        </ButtonPrimary>
      </div>
      <div className={styles.ErrorPage__footer}>
        <Footer />
      </div>
    </>
  );
};
