import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { ReactComponent as BlockIcon } from './assets/svg/Block.svg'
import { ReactComponent as BlockIcon1 } from './assets/svg/Block1.svg'
import { ReactComponent as GhostWithArrowsIcon } from './assets/svg/GhostWithArrows.svg'
import { ReactComponent as GhostWithArrowsMobileIcon } from './assets/svg/GhostWithArrowsMobile.svg'

import { ReactComponent as Copleaks } from './assets/companyLogos/Copleaks.svg'
import { ReactComponent as Crossplag } from './assets/companyLogos/Crossplag.svg'
import { ReactComponent as GPTZero } from './assets/companyLogos/GPTZero.svg'
import { ReactComponent as Originality } from './assets/companyLogos/Originality.svg'
import { ReactComponent as Turnitin } from './assets/companyLogos/Turnitin.svg'
import { ReactComponent as Winstonai } from './assets/companyLogos/Winstonai.svg'
import { ReactComponent as Zerogpt } from './assets/companyLogos/Zerogpt.svg'
import { useTranslation } from 'react-i18next'

export const DodgeAiChekers_shadowgpt: React.FC = () => {
    const { t } = useTranslation()
    return (
        <div className={`${styles.DodgeAiCheckers} app-container`}>
            <div className={styles.DodgeAiCheckers__textContainer}>
                <h2 className={styles.DodgeAiCheckers__title}>
                    {t('humanizer.convert.title_shadowgpt')}
                </h2>
                <p className={styles.DodgeAiCheckers__subTitle}>
                    {t('humanizer.convert.desc_shadowgpt')}
                </p>
            </div>
            <div className={styles.DodgeAiCheckers__blockIcons}>
                <div className={styles.DodgeAiCheckers__blockIcon}>
                    <BlockIcon />
                </div>
                <div
                    className={`${styles.DodgeAiCheckers__arrowIcon} ${styles.DodgeAiCheckers__GhostWithArrows}`}
                >
                    <GhostWithArrowsIcon />
                </div>
                <div className={styles.DodgeAiCheckers__GhostWithArrowsMobile}>
                    <GhostWithArrowsMobileIcon />
                </div>
                <div className={styles.DodgeAiCheckers__blockIcon}>
                    <BlockIcon1 />
                </div>
            </div>
            <div
                className={`${styles.DodgeAiCheckers__textContainer} ${styles.DodgeAiCheckers__margin}`}
            >
                <h2 className={styles.DodgeAiCheckers__title}>
                    {t('humanizer.convert.dodge')}
                </h2>
                <p className={styles.DodgeAiCheckers__subTitle}>
                    {t('humanizer.convert.dodgeText_shadowgpt')}
                </p>
            </div>
            <div className={styles.iconsContainer}>
                <Turnitin
                    className={styles.DodgeAiCheckers__companyIcon}
                    width={114}
                    height={34}
                />

                <GPTZero
                    className={styles.DodgeAiCheckers__companyIcon}
                    width={114}
                    height={34}
                />
                <Originality
                    className={styles.DodgeAiCheckers__companyIcon}
                    width={123}
                    height={30}
                />
                <Crossplag
                    className={styles.DodgeAiCheckers__companyIcon}
                    width={116}
                    height={27}
                />
                <Zerogpt
                    className={styles.DodgeAiCheckers__companyIcon}
                    width={99}
                    height={30}
                />
                <Copleaks
                    className={styles.DodgeAiCheckers__companyIcon}
                    width={120}
                    height={33}
                />

                <Winstonai
                    className={styles.DodgeAiCheckers__companyIcon}
                    width={117}
                    height={31}
                />
            </div>
        </div>
    )
}
