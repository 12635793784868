import React, { useState, RefObject, useRef, useEffect } from 'react'
import styles from './style.module.scss'
import { ButtonPrimary, CopyElement, TextDetectionResult } from '@components'

import { ReactComponent as AiIcon } from '../../assets/icons/AI.svg'
import { ReactComponent as StickIcon } from '../../assets/icons/Stick.svg'
import { ReactComponent as CopyIcon } from './assets/svg/Copy.svg'
import { ReactComponent as SampleIcon } from './assets/svg/Sample.svg'
import { SAMPLE_TEXT } from './constants'
import { Action } from './components'
import { useUnit } from 'effector-react'
import { combine } from 'effector'
import { $user } from '../../features/app/model'
import { useNavigate } from 'react-router-dom'
import {
    $aiDetect,
    $aiReDetect,
    $humanizedText,
    $humanizerInputValue,
    aiDetectFx,
    aiReDecectFx,
    humanizeFx,
    rehumanizeFx,
    humanizerInputHandler,
} from '../../features/humanizer/model'
import { useTranslation } from 'react-i18next'
import { makeToast, makeSubscriptionToast } from '../../shared/ui/toast'

const countWords = (str: string) => {
    str = str.trim()
    return str.split(/\s+/).length
}

export const HumanizerInput: React.FC<React.HTMLAttributes<HTMLDivElement>> = (
    props
) => {
    const minHeight = 300;
    const maxHeight = 700;
    const defHeight = 400;

    const { t } = useTranslation()
    const [humanizer, humanizerLoading] = useUnit([
        humanizeFx,
        combine(humanizeFx.pending, rehumanizeFx.pending, (a: boolean, b: boolean) => a || b)
    ])

    const [rehumanizer] = useUnit([
        rehumanizeFx
    ])

    const humanizedText = useUnit($humanizedText)
    const [textareaHeight, setTextareaHeight] = useState(minHeight);
    const [isDragging, setIsDragging] = useState(false);
    const [manuallyResized, setManuallyResized] = useState(false);

    const inputTextareaRef = useRef<HTMLTextAreaElement>(null);
    const outputTextareaRef = useRef<HTMLTextAreaElement>(null);
    const resizeHandleRef = useRef<HTMLDivElement>(null);
    
    const handleMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true); 
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (isDragging && inputTextareaRef.current && outputTextareaRef.current) {
            const containerRect = inputTextareaRef.current.parentElement?.getBoundingClientRect();
            if (containerRect) {
                const newHeight = Math.min(Math.max(e.clientY - containerRect.top, minHeight), maxHeight);
                setTextareaHeight(newHeight);
                inputTextareaRef.current.style.height = `${newHeight}px`;
                outputTextareaRef.current.style.height = `${newHeight}px`;
                setManuallyResized(true);
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);
    
    const handleResize = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const textarea = event.target;
        setTextareaHeight(textarea.scrollHeight);
    };
    const [isHumanizedResultShown, setIsHumanizedResultShown] = useState(false)

    const [aiDetect, aiDetectLoading] = useUnit([
        aiDetectFx,
        aiDetectFx.pending,
    ])

    const reDetectAi = useUnit($aiReDetect)

    const [reDetectFx, reDetectLoading] = useUnit([
        aiReDecectFx,
        aiReDecectFx.pending,
    ])

    const inputValue = useUnit($humanizerInputValue)

    const aiDetectRes = useUnit($aiDetect)
    const user = useUnit($user)

    const navigate = useNavigate()

    const ref: RefObject<HTMLDivElement> = useRef(null)
    const textareaRef: RefObject<HTMLTextAreaElement> = useRef(null)

    const [containerMaxHeqight, setContainerMaxHeight] = useState<any>('auto')
    const [textDetectionShow, setTextDetectionShow] = useState(false)
    const [aiReDetectShow, setAiReDetectShow] = useState(false)
    const [maxWords, setMaxWords] = useState(300)

    useEffect(() => {
        if (Object.keys(user) && user.subscription) {
            setMaxWords(user.subscription.maxWords)
        }
    }, [user])

    useEffect(() => {
        if (user && user.subscription) {
            console.log('User Subscription Type:', user.subscription.type);
        } else {
            console.log('User Subscription: Not found or not loaded');
        }
    }, [user]);

const textAreaChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    humanizerInputHandler(e.target.value);
    
    if (inputTextareaRef.current) {
        if (!manuallyResized) {
            // Reset height to auto to calculate the new content height
            inputTextareaRef.current.style.height = 'auto';
            
            // Calculate new height
            const scrollHeight = inputTextareaRef.current.scrollHeight;
            
            // Use the minimum of scrollHeight, defHeight, and maxHeight
            const newHeight = Math.min(Math.max(scrollHeight, minHeight), defHeight);
            
            inputTextareaRef.current.style.height = `${newHeight}px`;
            setTextareaHeight(newHeight);
        }
    }
    
    // Sync output textarea height if it exists
    if (outputTextareaRef.current) {
        outputTextareaRef.current.style.height = `${textareaHeight}px`;
    }
};

    const pasteActionClickHandler = async () => {
        const text = await navigator.clipboard.readText()
        humanizerInputHandler(text)
        textareaRef.current?.focus()
    }

    const tryASampleClickHandler = () => {
        humanizerInputHandler(SAMPLE_TEXT)
    }

    const humanizerHandler = async () => {
        if (!user.subscription) {
            makeToast('error', t('history.toast.noSubscription'));
            return;
        }
    
        const enoughRequests = user.subscription.requestsLeft > 0;
        const check = inputValue.length > 2;
        const isTrial = user.subscription.title.toUpperCase() === 'TRIAL';
        const isTooManyWords = countWords(inputValue) > maxWords;
    
        if (isTooManyWords) {
            makeToast('error', t('humanizer.toast.tooLong'))
            return;
        }
    
        if (enoughRequests) {
            if (check) {
                user.subscription.requestsLeft = user.subscription.requestsLeft - 1;
                setIsHumanizedResultShown(false);
                await humanizer(inputValue);
                setIsHumanizedResultShown(true);
            } else {
                makeToast('error', t('history.toast.tooShort'));
            }
        } else {
            makeSubscriptionToast(isTrial, false, t);
        }
    }

    const rehumanizerHandler = async () => {
        if (!user.subscription) {
            makeToast('error', t('history.toast.noSubscription'));
            return;
        }
    
        const enoughRequests = user.subscription.requestsLeft > 0;
        const check = inputValue.length > 2;
        const isTrial = user.subscription.title.toUpperCase() === 'TRIAL';
        const isTooManyWords = countWords(inputValue) > maxWords;
    
        if (isTooManyWords) {
            makeToast('error', t('humanizer.toast.tooLong'))
            return;
        }
    
        if (enoughRequests) {
            if (check) {
                setIsHumanizedResultShown(false);
                await rehumanizer(inputValue);
                setIsHumanizedResultShown(true);
            } else {
                makeToast('error', t('history.toast.tooShort'));
            }
        } else {
            makeSubscriptionToast(isTrial, false, t);
        }
    }

    // useEffect(() => {
    //   if (inputValue.length > 400) {
    //     makeToast("error", "long pennis")
    //   }
    // }, [])

    const detectAi = (type: string = '') => {
        if (inputValue.length > 2) {
            const isTrial = user.subscription?.title.toUpperCase() === 'TRIAL';
            const isTooManyWords = countWords(inputValue) > (user.subscription ? maxWords : 300);
    
            if (isTooManyWords) {
                makeToast('error', t('humanizer.toast.tooLong'))
                return;
            }
    
            if (user.subscription) {
                if (user.subscription.requestsLeft > 0) {
                    if (type === '') {
                        setTextDetectionShow(true);
                        aiDetect(inputValue);
                    } else if (humanizedText && type !== '') {
                        setAiReDetectShow(true);
                        reDetectFx(humanizedText);
                    }
                    // Decrement the requests left
                    user.subscription.requestsLeft--;
                } else {
                    makeSubscriptionToast(isTrial, false, t);
                    return;
                }
            } else {
                // User is not subscribed, allow detection but with limitations
                if (type === '') {
                    setTextDetectionShow(true);
                    aiDetect(inputValue);
                } else if (humanizedText && type !== '') {
                    setAiReDetectShow(true);
                    reDetectFx(humanizedText);
                }
            }
        } else {
            makeToast('error', t('humanizer.toast.fillRequired'));
        }
    }

    const renderActions = () => {
        return inputValue ? null : (
            <div className={styles.ActionWrapper}>
                <div className={styles.ActionWrapper__container}>
                    <Action
                        clickHandler={() => {
                            tryASampleClickHandler()
                        }}
                        icon={<SampleIcon />}
                        text={t(`humanizer.trySample`)}
                    />
                    <Action
                        clickHandler={() => {
                            pasteActionClickHandler()
                        }}
                        icon={<CopyIcon width={18} fill="white" height={18} />}
                        text={t(`humanizer.pasteText`)}
                    />
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (ref.current) {
            setContainerMaxHeight(ref.current?.getBoundingClientRect().height)
        }
    }, [])

    const disableButton = () => {
        if (inputValue.length > 2) {
            if (user.subscription && countWords(inputValue) <= maxWords) {
                return false
            } else if (!user.subscription && countWords(inputValue) <= 300)
                return false
            else return true
        }
        return true
    }

   /* useEffect(() => {
        const delay = setTimeout(() => {
            if (countWords(inputValue) >= maxWords) {
                makeToast('error', t('humanizer.toast.tooLong'))
            }
        }, 4000)

        return () => clearTimeout(delay)
    }, [inputValue, user, t, maxWords])*/

    return (
        <div
            ref={ref}
            className={`${styles.HumanizerInput} ${
                props.className ? props.className : ''
            } ${textDetectionShow ? styles.HumanizerInput_animated : ''}`}
        >    
            <div className={styles.HumanizerInput__flexWrapper}>
                <div
                    className={`${styles.HumanizerInput__input} ${styles.HumanizerInput__rightBorder}`}
                >
                    <div
                        className={`${styles.HumanizerInput__flexWrapper} ${styles.HumanizerInput__aliginMod}`}
                    >
                        <p className={styles.HumanizerInput__title}>
                            {t(`humanizer.yourInput`)}
                        </p>
                        <p className={styles.HumanizerInput__title}>
                            <span className={styles.HumanizerInput__basicPlan}>
                                {Object.keys(user).length
                                    ? user.subscription.title
                                    : 'General'}{' '}
                                Plan
                            </span>{' '}
                            <span className={`
                                ${styles.HumanizerInput__greyColor}
                                ${countWords(inputValue) > maxWords ? styles.HumanizerInput__redColor : ''}
                            `}>
                                {countWords(inputValue)} / {maxWords}{' '}
                                {` ${t('history.words')}`}
                            </span>
                        </p>
                    </div>
                    <div className={styles.HumanizerInput__fieldWrapper}>
                        {renderActions()}
                        <textarea
                            ref={inputTextareaRef}
                            onChange={textAreaChangeHandler}
                            value={inputValue}
                            className={`${styles.HumanizerInput__field} ${styles.resizableTextarea}`}
                            placeholder={t(`humanizer.placeholder1`)}
                            style={{ 
                                height: `${manuallyResized ? textareaHeight : Math.max(minHeight, Math.min(textareaHeight, defHeight))}px`, 
                                minHeight: `${minHeight}px`, 
                                maxHeight: `${maxHeight}px` 
                            }}
                        />
                        <div 
                            ref={resizeHandleRef}
                            className={styles.resizeHandle}
                            onMouseDown={handleMouseDown}
                        />
                    </div>
                    <div className={`${styles.buttons}`}>
                        <ButtonPrimary
                            onClick={() => {
                                detectAi();
                            }}
                            className={styles.buttons__centeringMod}
                            icon={<AiIcon />}
                            colorScheme="dark-orange"
                        >
                            {t(`humanizer.scanForAi`)}
                        </ButtonPrimary>
                        <ButtonPrimary
                            onClick={() => {
                                if (Object.keys(user).length) {
                                    humanizerHandler();
                                } else {
                                    navigate('/auth');
                                }
                            }}
                            className={styles.buttons__centeringMod}
                            icon={<StickIcon />}
                            colorScheme="orange"
                            disabled={humanizerLoading}
                        >
                            {humanizerLoading ? t('humanizer.processingBtn') : t('humanizer.humanizeBtn')}
                        </ButtonPrimary>
                    </div>
                    <div>
                        {textDetectionShow && (
                            <TextDetectionResult
                                data={aiDetectRes}
                                bottomAiResultBadge={false}
                                loading={aiDetectLoading}
                            />
                        )}
                    </div>
                </div>
                <div className={`${styles.HumanizerInput__result}`}>
                    <div className={`${styles.HumanizerInput__copyContainer}`}>
                        <p className={styles.HumanizerInput__title}>
                            {t(`humanizer.result`)}
                        </p>
                        <CopyElement
                            onClick={() =>
                                humanizedText.length &&
                                navigator.clipboard.writeText(humanizedText)
                            }
                        />
                    </div>
                    {humanizerLoading ? (
                        <div className={styles.Loader}>
                            <div className={styles.Loader__bar}>
                                <div
                                    className={
                                        styles.Loader__bar +
                                        ' ' +
                                        styles.Loader__barAnimated
                                    }
                                ></div>
                            </div>
                            <p> {t(`humanizer.processing`)}</p>
                        </div>
                    ) : (
                        <textarea
                        ref={outputTextareaRef}
                        disabled={true}
                        className={`${styles.HumanizerInput__result_text} ${styles.resizableTextarea}`}
                        style={{ 
                            height: `${manuallyResized ? textareaHeight : Math.max(minHeight, Math.min(textareaHeight, defHeight))}px`, 
                            minHeight: `${minHeight}px`, 
                            maxHeight: `${maxHeight}px` 
                        }}
                    >
                        {humanizedText
                            ? humanizedText
                            : t(`humanizer.resultPlaceholder`)}
                    </textarea>
                    )}
                    <div
                        className={`${styles.buttons} ${styles.buttonAdaptive}`}
                        style={humanizerLoading ? { marginTop: 'auto' } : {}}
                    >
                        {humanizedText && (
                            <>
                                <ButtonPrimary
                                    onClick={() => detectAi('redetect')}
                                    className={styles.buttons__centeringMod}
                                    icon={<AiIcon />}
                                    disabled={aiDetectLoading || humanizerLoading }
                                    colorScheme="dark-orange"
                                >
                                    {t(`humanizer.rescanAi`)}
                                </ButtonPrimary>
                                <ButtonPrimary
                                    onClick={() => {
                                        setAiReDetectShow(false)
                                        rehumanizerHandler()
                                    }}
                                    className={styles.buttons__centeringMod}
                                    icon={<StickIcon />}
                                    colorScheme="orange"
                                    disabled={aiDetectLoading || humanizerLoading }
                                    >
                                        {humanizerLoading ? t('humanizer.processingBtn') : t('humanizer.rehumanize')}
                                </ButtonPrimary>
                            </>
                        )}
                    </div>
                    <div>
                        {aiReDetectShow && (
                            <TextDetectionResult
                                data={reDetectAi}
                                bottomAiResultBadge={false}
                                loading={reDetectLoading}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
