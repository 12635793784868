import React, {useEffect, useRef} from 'react'
import styles from './style.module.scss'
import {useNavigate} from 'react-router-dom'
import {
    ShadowChatList,
    CopyElement,
    Badge,
    TextDetectionResult,
} from '@components'
import {ReactComponent as CrownIcon} from '@assets/icons/Crown.svg'
import {Input, Prompt} from './components'
import avatar from '@assets/webp/AvatarHistory.webp'
import historyStyles from '../History/style.module.scss'
import shadowGPT from '@assets/webp/ShadowGPT.webp'
import humInputStyles from '../../../../components/HumanizerInput/style.module.scss'
import {useUnit} from 'effector-react'
import {ReactComponent as StarsIcon} from '../History/assets/svg/Stars.svg'
import {ReactComponent as RobotIcon} from '../History/assets/svg/robot.svg'

import {ReactComponent as StarsSecondIcon} from '../../components/SideMenu/assets/Stars.svg'
import {ReactComponent as StickIcon} from '@assets/icons/Stick.svg'
import {ReactComponent as AiIcon} from '@assets/icons/AI.svg'
import {
    $activeChat,
    $chatInput,
    $chatList,
    $chatMessages,
    $messageResponse,
    activeChatEvent,
    chatInputHandler,
    chatListHandler,
    chatMessagesFx,
    createChatFx,
    sendMessageFx,
} from '../../../../features/chat/model'
import {$user} from '../../../../features/app/model'
import {$aiDetect, aiDetectFx} from '../../../../features/humanizer/model'
import {
    ChatDetailedResponseDto,
    ChatMessageResponseDto,
    MessageRequestResponseDto,
} from '../../../../http/Api'
import $api from '../../../../http'
import {Trans, useTranslation} from 'react-i18next'
import {makeToast} from '../../../../shared/ui/toast'

function countWords(str: string) {
    if (str.length) {
        str = str.trim()
        var words = str.split(/\s+/)
        return words.length
    }
}

const copyHandler = (text: string | undefined) => {
    if (text) navigator.clipboard.writeText(text)
}

const LoaderMsg = () => {
    return (
        <div className={styles.BotMessage}>
            <div
                className={
                    historyStyles.Content__nickContainer +
                    ' ' +
                    styles.BotMessage__nickContainer
                }
            >
                <img src={shadowGPT} alt=""/>
                <p className={historyStyles.Content__nick}>HumanizeAI</p>
            </div>
            <div className={humInputStyles.Loader__bar}>
                <div
                    className={
                        humInputStyles.Loader__bar +
                        ' ' +
                        humInputStyles.Loader__barAnimated
                    }
                ></div>
            </div>
        </div>
    )
}

const GPTResponse = ({response, chatId}: any): any => {
    const {t} = useTranslation()
    const [aiDetect, loading, aiResponse] = useUnit([
        aiDetectFx,
        aiDetectFx.pending,
        $aiDetect,
    ])
    const [sendMessage, messageLoading, sendMessageResponse] = useUnit([
        sendMessageFx,
        sendMessageFx.pending,
        $messageResponse,
    ])
    const user = useUnit($user)
    const [textDetectionShow, setTextDetectionShow] = React.useState(false)

    const [aiDetectData, setAiDetectData] = React.useState([])
    const [detectionLoading, setDetectionLoading] = React.useState(false)

    const handleAi = () => {

        // @ts-ignore
        let tooLong = countWords(response) > user.subscription.maxWords
        let tooShort = response.length > 2

        if (!tooLong) {
            if (tooShort) {
                setTextDetectionShow(true)
                setDetectionLoading(true)
                aiDetect(response).then((res: any) => {
                    setAiDetectData(res)
                    setDetectionLoading(false)
                    if (res === undefined) {
                        alert('err')
                        setTextDetectionShow(false)
                    }
                })
            } else makeToast('error', t('history.toast.tooShort'))
        } else {
            makeToast('error', t('humanizer.toast.tooLong'))
        }
    }

    const reGenerate = () => {
        if (response.length > 2) {
            user.subscription.requestsLeft =
                user.subscription.requestsLeft > 0
                    ? user.subscription.requestsLeft - 1
                    : 0
            sendMessage({text: response, chatId})
        } else {
            makeToast('error', t('history.toast.tooShort'))
        }
    }
    return (
        <>
            <div
                className={styles.ChatMessage_container}
                style={{marginBottom: '24px'}}
            >
                <div
                    className={
                        historyStyles.ChatMessage__item +
                        ' ' +
                        historyStyles.ChatMessage__item_bg
                    }
                    style={{margin: '0 0 8px 0'}}
                >
                    <div className={historyStyles.Content__nickContainer}>
                        <img src={shadowGPT} alt=""/>
                        <p className={historyStyles.Content__nick}>HumanizeAI</p>
                    </div>
                    <p className={historyStyles.Content__text_main}>
                        {response}
                    </p>
                    <div
                        className={historyStyles.Content__copyContainer}
                        style={
                            textDetectionShow ? {margin: '0 0 70px 0'} : {}
                        }
                    >
                        <CopyElement
                            onClick={() => copyHandler(response && response)}
                            className={historyStyles.Content__copyItem}
                        />
                        <small className={historyStyles.Content__wordsCount}>
                            {countWords(response && response)}{' '}
                            {t('history.words')}
                        </small>
                    </div>
                    {textDetectionShow && (
                        <TextDetectionResult
                            data={aiDetectData}
                            bottomAiResultBadge={false}
                            loading={detectionLoading}
                        />
                    )}
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                    }}
                >
                    <Badge
                        text={t('humanizer.history.btn')}
                        withBackground={true}
                        icon={<StarsIcon/>}
                        onClick={() => {
                            reGenerate()
                        }}
                    />
                    <Badge
                        text={t('chat.checkFor')}
                        withBackground={true}
                        icon={<RobotIcon/>}
                        onClick={handleAi}
                    />
                </div>
            </div>
        </>
    )
}

export const ShadowGPT: React.FC = () => {
    const ref = useRef<any>(null)
    const user = useUnit($user)
    const activeChatState = useUnit($activeChat)
    const [sendMessage, loading] = useUnit([
        sendMessageFx,
        sendMessageFx.pending,
    ])
    const chatList = useUnit($chatList)

    ///

    const [messages, getMessages, messagesLoading] = useUnit([
        $chatMessages,
        chatMessagesFx,
        chatMessagesFx.pending,
    ])

    const chatInput = useUnit($chatInput)

    useEffect(() => {
        if (Object.keys(activeChatState).length) {
            getMessages(activeChatState.id)
        }
    }, [activeChatState, loading])

    ///
    const navigate = useNavigate()

    const upgradeButtonHandler = () => navigate('/pricing')

    const submitMessageButtonHandler = async (text: string, chatId: string) => {
        if (Object.keys(activeChatState).length) {
            sendMessage({text, chatId})
        }
    }


    const renderMessages = () => {
        return (
            Object.keys(messages).length &&
            messages.map((it: MessageRequestResponseDto) => {
                return (
                    <>
                        <div className={historyStyles.ChatMessage__item}>
                            <div
                                className={historyStyles.Content__nickContainer}
                            >
                                <img src={avatar} alt=""/>
                                <p className={historyStyles.Content__nick}>
                                    {t('chat.you')}
                                </p>
                            </div>
                            <p className={historyStyles.Content__text_main}>
                                {it.request}
                            </p>
                            <div
                                className={historyStyles.Content__copyContainer}
                            >
                                <CopyElement
                                    onClick={() => copyHandler(it.request)}
                                    className={historyStyles.Content__copyItem}
                                />
                                <small
                                    className={
                                        historyStyles.Content__wordsCount
                                    }
                                >
                                    {countWords(it.request) === 1
                                        ? `${countWords(it.request)} ${t(
                                            'history.word'
                                        )}`
                                        : `${countWords(it.request)} ${t(
                                            'history.words'
                                        )}`}
                                </small>
                            </div>
                        </div>
                        {it.responses[0].text ? (
                            <GPTResponse
                                response={it.responses[0].text}
                                chatId={activeChatState.id}
                            />
                        ) : null}
                    </>
                )
            })
        )
    }

    const getStarted = () => {
        if (chatList.length) {
            activeChatEvent(chatList[0])
            if (chatInput.length > 2)
                sendMessage({text: chatInput, chatId: chatList[0].id})
            else makeToast('error', t('history.toast.tooShort'))
            chatInputHandler('')
        } else {
            createChatFx(`${t('chat.chatName')} #${chatList.length + 1}`).then(
                (res: any) => {
                    let pinedChats = chatList.filter(
                        (chat: any) => chat.isPined
                    )
                    let notPinedChats = chatList.filter(
                        (chat: any) => !chat.isPined
                    )

                    activeChatEvent(res)
                    sendMessage({text: chatInput, chatId: res.id})
                    chatInputHandler('')
                    chatListHandler([...pinedChats, res, ...notPinedChats])
                }
            )
        }
    }

    useEffect(() => {
        if (ref.current && Object.keys(activeChatState).length) {
            ref.current.scrollTop = ref.current.scrollHeight
        }
    }, [activeChatState])

    const {t, ready} = useTranslation()

    const START_PROMPTS = t('chat.start.list', {returnObjects: true})

    return (
        <div className={styles.ShadowGPT}>
            <div className={styles.ShadowGPT__sidebar}>
                <ShadowChatList/>
                <div className={styles.ShadowGPT__tariffContainer}>
                    <div
                        className={
                            styles.ShadowGPT__tariffContainer_indicatorContainer
                        }
                    >
                        {Object.keys(user).length > 0 ? (
                            <>
                                <small>
                                    {user?.subscription
                                        ? user?.subscription.title
                                        : 'General'}{' '}
                                    {t('chat.plan')}
                                </small>
                                <div
                                    className="progress"
                                    style={{margin: '4px 0'}}
                                >
                                    <div
                                        style={{
                                            width:
                                                ((user.subscription
                                                            .totalRequests -
                                                        user.subscription
                                                            .requestsLeft) /
                                                    user.subscription
                                                        .totalRequests) *
                                                100 +
                                                '%',
                                        }}
                                        className="progress_bar"
                                    ></div>
                                </div>
                                <small>
                                    {user.subscription.totalRequests -
                                        user.subscription.requestsLeft}{' '}
                                    / {user.subscription!.totalRequests}{' '}
                                    {t('chat.requests')}
                                </small>
                            </>
                        ) : null}
                    </div>
                    <div
                        onClick={upgradeButtonHandler}
                        className={styles.ShadowGPT__upgrade}
                    >
                        <small>{t('chat.upgrade')}</small>
                        <CrownIcon width={18} height={14}/>
                    </div>
                </div>
            </div>
            <div className={styles.ShadowGPT__main}>
                <div className={styles.ShadowGPT__main_noiseBackground}/>
                <div className={styles.ShadowGPT__main_content}>
                    <div className={styles.ShadowGPTContent}>
                        {Object.keys(activeChatState).length ? (
                            <div className={styles.ChatList}>
                                <div
                                    className={styles.ChatList__inner}
                                    ref={ref}
                                >
                                    {renderMessages()}
                                    {loading ? <LoaderMsg/> : null}
                                </div>
                            </div>
                        ) : (
                            <>
                                <h2>
                                    {t('chat.startWith')}
                                    <span
                                        className={
                                            styles.ShadowGPTContent__titleSpan
                                        }
                                    >
                                        {' '}
                                        HumanizeAI
                                    </span>
                                </h2>
                                <div
                                    className={
                                        styles.ShadowGPTContent__flexWrapper
                                    }
                                >
                                    {ready &&
                                        // @ts-ignore
                                        START_PROMPTS.map((el: any, i: any) => (
                                            <Prompt
                                                colorScheme={
                                                    i === 0 ? 'orange' : 'basic'
                                                }
                                                title={el.title}
                                                description={el.description}
                                                icon={
                                                    el.title.includes(
                                                        'Humanize'
                                                    ) ? (
                                                        <StickIcon fill="#fff"/>
                                                    ) : el.title.includes(
                                                        'Generate with GPT-4'
                                                    ) ? (
                                                        <StarsSecondIcon fill="#fff"/>
                                                    ) : (
                                                        <AiIcon fill="#fff"/>
                                                    )
                                                }
                                                buttonTitle={el.buttonTitle}
                                                onClick={() => {
                                                    if (
                                                        el.title.includes(
                                                            'Humanize'
                                                        ) ||
                                                        el.title.includes(
                                                            'AI detection test'
                                                        )
                                                    ) {
                                                        navigate('/')
                                                    } else {
                                                        getStarted()
                                                    }
                                                }}
                                            />
                                        ))}
                                </div>
                            </>
                        )}
                    </div>
                    <Input
                        callback={submitMessageButtonHandler}
                        getStarted={
                            Object.keys(activeChatState).length > 0
                                ? false
                                : true
                        }
                        getStartedFunc={getStarted}
                    />
                </div>
            </div>
        </div>
    )
}
