import React from 'react'
import styles from './style.module.scss'
import { ButtonPrimary, TransparentText } from '@components'
import { TextBlock } from './component/TextBlock'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

export const ShadowsReference_shadowgpt: React.FC = () => {
    const navigate = useNavigate()

    const { t } = useTranslation()

    return (
        <div className={styles.ShadowsReference}>
            <div className={styles.ShadowsReference__background} />
            <div
                className={`${styles.ShadowsReference__content} app-container`}
            >
                <div className={styles.ShadowsReference__leftPart}>
                    <h2 className={styles.ShadowsReference__title}>
                        <Trans
                            i18nKey="humanizer.use.title"
                            components={{
                                transparent: <TransparentText />,
                            }}
                        />
                    </h2>
                    <p className={styles.ShadowsReference__subTitle}>
                        {t('humanizer.use.text5')}
                    </p>
                    <div className={styles.ShadowsReference__buttonContainer}>
                        <ButtonPrimary
                            colorScheme="orange"
                            onClick={() => navigate('/generation/shadow-chat')}
                        >
                            {t('humanizer.use.btn')}
                        </ButtonPrimary>
                    </div>
                </div>
                <TextBlock />
            </div>
        </div>
    )
}
