import React from "react";
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Icon } from "@assets/icons/ShadowGPTlogo.svg";
import styles from "./style.module.scss";

export const Logo: React.FC = () => {
  const navigate = useNavigate();

  const logoClickHandler = () => navigate("/");

  return (
    <div onClick={logoClickHandler} className={styles.Logo}>
      <div>
        <Icon id={`${Math.random()}`} className={styles.Logo__icon} />
      </div>
      <div className={styles.Logo__caption}>HumanizeAI.now</div>
    </div>
  );
};
