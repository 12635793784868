import React from 'react'
import styles from './style.module.scss'
import { ReactComponent as QueotesBottomIcon } from './assets/QuotesBottom.svg'
import { ReactComponent as QueotesTopIcon } from './assets/QuotesTop.svg'

import avatar from './assets/Image-60.webp'

type Props = {
    review: string
    post: string
    name: string
    photoPath: string
}

export const ReviewBlock: React.FC<Props> = ({
    name,
    photoPath,
    post,
    review,
}) => {
    return (
        <div className={styles.ReviewBlock}>
            <small>{review}</small>
            <div className={styles.ReviewBlock__avatarContainer}>
                <img
                    src={photoPath}
                    className={styles.ReviewBlock__avatar}
                    alt=""
                />
                <div>
                    <p>{name}</p>
                    <p>{post}</p>
                </div>
            </div>
            <QueotesTopIcon className={styles.ReviewBlock__quotesTop} />
            <QueotesBottomIcon className={styles.ReviewBlock__quotesBottom} />
        </div>
    )
}
