import React, { useState } from 'react'
import styles from './style.module.scss'
import { ReviewBlock } from './components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ReactComponent as ArrowLeftIcon } from './assets/ArrowLeft.svg'

// MOCK-DATA
import avatar from './components/ReviewBlock/assets/Image-60.webp'
import avatar2 from './components/ReviewBlock/assets/2.webp'
import avata3 from './components/ReviewBlock/assets/3.webp'
import avatar4 from './components/ReviewBlock/assets/4.webp'
import avatar5 from './components/ReviewBlock/assets/5.webp'
import avatar6 from './components/ReviewBlock/assets/6.webp'
import { useTranslation } from 'react-i18next'

const avatars = [avatar, avatar2, avata3, avatar4, avatar5, avatar6]

// MOCK-DATA

export const ChooseUsSection_shadowgpt: React.FC = () => {
    const [swiper, setSwiper] = useState<any>(null)
    const [activeArrow, setActiveArrow] = useState<number>(0)

    const { t, ready } = useTranslation()

    const list = t('whyUs.data_shadowgpt', { returnObjects: true })

    const renderMobileReviews = () => {
        //@ts-ignore
        return list.map(({ review, name, post, photoPath }, i) => (
            <ReviewBlock
                photoPath={avatars[i]}
                name={name}
                post={post}
                review={review}
                key={`${i}-ReviewBlock`}
            />
        ))
    }

    const renderDesktopReviews = () => {
        //@ts-ignore
        return list.map(({ review, name, post, photoPath }, i) => (
            <SwiperSlide>
                <ReviewBlock
                    photoPath={avatars[i]}
                    name={name}
                    post={post}
                    review={review}
                    key={`${i}-ReviewBlock`}
                />
            </SwiperSlide>
        ))
    }

    const lefArrowClickHandler = () => {
        swiper.slidePrev()
    }

    const rightArrowClickHandler = () => {
        swiper.slideNext()
    }

    return (
        <section className={`${styles.ChooseUsSection}`}>
            <div
                className={
                    'app-container' +
                    ' ' +
                    styles.ChooseUsSection__positionWrapper
                }
            >
                <div className={styles.ChooseUsSection__maskVectorEl} />
                <div className={styles.ChooseUsSection__titleContainer}>
                    <h2>{t('whyUs.title')}</h2>
                    <div className={styles.ChooseUsSection__arrowContainer}>
                        <div
                            onClick={() => {
                                lefArrowClickHandler()
                                setActiveArrow(0)
                            }}
                            className={styles.ChooseUsSection__arrow}
                            style={
                                activeArrow === 0
                                    ? { background: 'rgba(248, 248, 248, 1)' }
                                    : { background: 'transparent' }
                            }
                        >
                            <svg
                                width="19"
                                height="16"
                                viewBox="0 0 19 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill={
                                        activeArrow === 0
                                            ? 'rgba(7,7,7,1)'
                                            : '#f8f8f8'
                                    }
                                    d="M8.7071 13.7929C9.0976 14.1834 9.0976 14.8166 8.7071 15.2071C8.3166 15.5976 7.6834 15.5976 7.2929 15.2071L1.5 9.4142C0.719 8.6332 0.719 7.3668 1.5 6.5858L7.2929 0.79289C7.6834 0.40237 8.3166 0.40237 8.7071 0.79289C9.0976 1.18342 9.0976 1.81658 8.7071 2.20711L3.9142 7H18C18.5523 7 19 7.4477 19 8C19 8.5523 18.5523 9 18 9H3.9142L8.7071 13.7929Z"
                                />
                            </svg>
                        </div>
                        <div
                            onClick={() => {
                                rightArrowClickHandler()
                                setActiveArrow(1)
                            }}
                            className={
                                styles.ChooseUsSection__arrow +
                                ' ' +
                                styles.ChooseUsSection__arrow_available +
                                ' ' +
                                styles.ChooseUsSection__arrow__right
                            }
                            style={
                                activeArrow === 1
                                    ? { background: 'rgba(248, 248, 248, 1)' }
                                    : { background: 'transparent' }
                            }
                        >
                            {/* <ArrowLeftIcon fill="rgba(7, 7, 7, 1)" /> */}
                            <svg
                                width="19"
                                height="16"
                                viewBox="0 0 19 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill={
                                        activeArrow === 1
                                            ? 'rgba(7,7,7,1)'
                                            : '#f8f8f8'
                                    }
                                    d="M8.7071 13.7929C9.0976 14.1834 9.0976 14.8166 8.7071 15.2071C8.3166 15.5976 7.6834 15.5976 7.2929 15.2071L1.5 9.4142C0.719 8.6332 0.719 7.3668 1.5 6.5858L7.2929 0.79289C7.6834 0.40237 8.3166 0.40237 8.7071 0.79289C9.0976 1.18342 9.0976 1.81658 8.7071 2.20711L3.9142 7H18C18.5523 7 19 7.4477 19 8C19 8.5523 18.5523 9 18 9H3.9142L8.7071 13.7929Z"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
                <div
                    className={
                        styles.ChooseUsSection__swiperContainer +
                        ' ' +
                        styles.ChooseUsSection__swiper
                    }
                >
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={3}
                        onSlideChange={() => {}}
                        onSwiper={(swiper: any) => setSwiper(swiper)}
                    >
                        {renderDesktopReviews()}
                    </Swiper>
                </div>
                <div
                    className={
                        styles.ChooseUsSection__swiperContainer +
                        ' ' +
                        styles.ChooseUsSection__swiperMobile
                    }
                >
                    {renderMobileReviews()}
                </div>
            </div>
        </section>
    )
}
