import React from 'react'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import styles from './toast.module.scss'

export const makeToast = (
    type: 'success' | 'error' | 'warn',
    message: string
) => {
    if (type === 'success') return toast.success(message)
    if (type === 'warn') return toast.warn(message)
    return toast.error(message)
}

let overlayElement: HTMLDivElement | null = null;

const createOverlay = (closeToast: () => void) => {
  if (!overlayElement) {
    overlayElement = document.createElement('div');
    overlayElement.className = styles.overlay;
    overlayElement.addEventListener('click', () => {
      closeToast();
      hideOverlay();
    });
    document.body.appendChild(overlayElement);
  }
};

const showOverlay = () => {
  if (overlayElement) {
    overlayElement.classList.add(styles.visible);
  }
};

const hideOverlay = () => {
  if (overlayElement) {
    overlayElement.classList.remove(styles.visible);
  }
};

const SUBSCRIPTION_TOAST_ID = 'subscription-toast'

export const makeSubscriptionToast = (isTrial: boolean, isTooManyWords: boolean = false, t: (key: string) => string) => {
    if (!toast.isActive(SUBSCRIPTION_TOAST_ID)) {
        toast.info(
            ({ closeToast }: { closeToast: () => void }) => {
                createOverlay(closeToast);
                showOverlay();
                
                const handleClose = () => {
                    closeToast();
                    hideOverlay();
                };

                return (
                    <div className={styles.subscriptionToast}>
                        <h3>{isTooManyWords ? t('subscriptionToast.isTooManyWords.title') : (isTrial ? t('subscriptionToast.isTrial.title') : t('subscriptionToast.subscriptionLimit.title'))}</h3>
                        {isTooManyWords ? (
                            <>
                                <div className={styles.toastText}>{t('subscriptionToast.isTooManyWords.desc1')}</div>
                                <div className={styles.toastText}>
                                 {t('subscriptionToast.isTooManyWords.desc2')}
                                </div>
                            </>
                        ) : isTrial ? (
                            <>
                                <div className={styles.toastText}>{t('subscriptionToast.isTrial.desc1')}</div>
                                <div className={styles.toastText}>
                                {t('subscriptionToast.isTrial.desc2')}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={styles.toastText}>{t('subscriptionToast.subscriptionLimit.desc1')}</div>
                                <div className={styles.toastText}>
                              {  t('subscriptionToast.subscriptionLimit.desc2')}
                                </div>
                            </>
                        )}
                        <a href="https://humanizeai.now/pricing" rel="noopener noreferrer">
                            <button onClick={handleClose}>
                                {isTrial || isTooManyWords ? t('subscriptionToast.isTrial.button') : t('subscriptionToast.subscriptionLimit.button')}
                            </button>
                            <div className={styles.guaranteeText}>
                            {t('subscriptionToast.moneyBack')}
                            </div>
                            
                        </a>
                    </div>
                );
            },
            {
                toastId: SUBSCRIPTION_TOAST_ID,
                position: "top-center",
                autoClose: false,
                closeOnClick: false,
                draggable: false,
                closeButton: true,
                className: styles.subscriptionToastContainer,
                icon: false,
                onClose: () => {
                    hideOverlay();
                }
            }
        )
    }
}