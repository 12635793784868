import React from 'react'
import styles from './style.module.scss'
import avatars from './assets/Avatars.webp'
import { useTranslation } from 'react-i18next'

export const SendEmailBlock: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className={`${styles.SendEmailBlock}`}>
            <div className={styles.SendEmailBlock__backgroundNoise} />
            <div className={`${styles.SendEmailBlock__wrapper} app-container`}>
                <div className={styles.SendEmailBlock__subtitle}>
                    <h2>{t(`support.title`)}</h2>
                    <p>
                        {t(`support.description`)}
                    </p>
                </div>
                <div className={styles.SendEmailBlock__mailBlock}>
                    <p>support@humanizeai.now</p>
                    <img
                        className={styles.SendEmailBlock__avatars}
                        src={avatars}
                        alt=""
                    />
                </div>
            </div>
        </div>
    )
}
