import $api from '../../http'

export const userQuery = async () => {
    try {
        const res = await $api.api.usersControllerGetMe()
        return res.data.data
    } catch (error) {
        console.log(error)
    }
}

export const userDeleteQuery = async () => {
    try {
        const res = await $api.api.usersControllerDeleteMe()
        return res.data
    } catch (error) {
        console.log(error)
    }
}

export const cancelSubscriptionQuery = async () => {
    try {
        const res = await $api.api.subscriptionsControllerCancelCurrentSubscription()
        return res.data
    } catch (error) {
        console.log(error)
    }
}

type UpdateUserType = {
    firstName?: string
    lastName?: string
    file?: File
}

export const updateUserQuery = async (props: UpdateUserType) => {
    try {
        const res = await $api.api.usersControllerUpdateMe({
            firstName: props.firstName,
            lastName: props.lastName,
            ...(props.file && { file: props.file }),
        })
        return res.data
    } catch (error) {
        console.log(error)
    }
}

type UserLangProps = {
    languageCode: string
    currencyCode: 'SEK' | 'USD'
}

export const updateUserLangQuery = async (props: UserLangProps) => {
    try {
        const res = await $api.api.usersControllerUpdateMe({
            languageCode: props.languageCode,
            currencyCode: props.currencyCode,
        })
        return res.data
    } catch (error) {
        console.log(error)
    }
}