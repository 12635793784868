import React from 'react'
import styles from './style.module.scss'
import avatar from './assets/avatar.webp'
import shadowGPTAvatar from './assets/avatarShdowGPT.webp'
import { ReactComponent as CheckIcon } from './assets/checkIcon.svg'
import { ReactComponent as SendIcon } from './assets/sendIcon.svg'
import { useTranslation } from 'react-i18next'

const NameTitle: React.FC<{ title: string; imagePath: string }> = ({
    title,
    imagePath,
}) => {
    return (
        <div className={styles.avatar}>
            <img alt="" className={styles.avatar__image} src={imagePath} />
            <p className={styles.avatar__title}>{title}</p>
        </div>
    )
}

export const TextBlock: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.TextBlock}>
            <div>
                <NameTitle imagePath={avatar} title={t('humanizer.use.you')} />
                <div className={styles.messageBlock}>
                    {t('humanizer.use.yourText')}
                </div>
                <NameTitle
                    imagePath={shadowGPTAvatar}
                    title={t('humanizer.use.chat_shadowgpt')}
                />
                <div className={styles.shadowGPTMessage}>
                    <p className={styles.shadowGPTMessage__text}>
                        {t('humanizer.use.text')}
                    </p>
                    <div className={styles.shadowGPTMessage__divider} />
                    <div className={styles.shadowGPTMessage__textContainer}>
                        <p className={styles.shadowGPTMessage__text}>
                            {t('humanizer.use.text2')}
                        </p>
                        <div className={styles.shadowGPTMessage__checkCaption}>
                            <CheckIcon />
                            <p
                                className={`${styles.shadowGPTMessage__text} ${styles.shadowGPTMessage__text_green}`}
                            >
                                {t('humanizer.use.good')}
                            </p>
                        </div>
                        <p className={styles.shadowGPTMessage__text}>
                            {t('humanizer.use.text3')}
                        </p>
                    </div>
                </div>
                <NameTitle imagePath={avatar} title={t('humanizer.use.you')} />
                <div className={styles.messageBlock__container}>
                    <div className={styles.messageBlock__sendIconContainer}>
                        <SendIcon className={styles.messageBlock__send} />
                    </div>
                    <div className={styles.messageBlock}>
                        {t('humanizer.use.yourText2')}
                    </div>
                </div>
            </div>
        </div>
    )
}
