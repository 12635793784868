    import i18n from 'i18next'
    import { initReactI18next } from 'react-i18next'
    import LanguageDetector from 'i18next-browser-languagedetector'
    import Backend from 'i18next-http-backend'

    i18n
        .use(LanguageDetector)
        .use(Backend)
        .use(initReactI18next)
        .init({
            debug: false,
            detection: {
                order: ['navigator', 'querystring', 'cookie', 'localStorage'],
                caches: ['localStorage']
            },
            supportedLngs: ['en', 'sv'],
            nonExplicitSupportedLngs: true,
            cache: {
                enabled: true,
            },
            fallbackLng: 'en', // Set English as fallback
            lng: 'en', // Set default language to English
            interpolation: {
                escapeValue: false,
            },
        })

    export default i18n
